<template>
  <div class="edit-company-sidebar">
    <v-form ref="form" style="width: 500px; padding: 20px">
      <CRInput
        v-model="companyFormData.name"
        label="Operator Name"
        disabled
      />
      <CRInput
        v-if="companyFormData.address.addressName"
        :value="companyFormData.address.addressName"
        type="text"
        label="Address"
        placeholder="Company Address"
        :readonly="true"
        :disabled="!hasPermissionToEdit"
        :clearable="hasPermissionToEdit"
        clear-icon="replay"
        @click:clear="handleAddressClear"
      />
      <CRAddressSelector
        v-else
        class="margin-b-4"
        display-field="addressName"
        :disabled="!hasPermissionToEdit"
        placeholder="Company Address"
        label="Address"
        @place-selected="handleAddressSelect"
        @place-cleared="handleAddressClear"
      />
      <CRInput
        v-model="companyFormData.email"
        :disabled="!hasPermissionToEdit"
        label="Primary Email Address"
        placeholder="Email"
        :rules="[
          (val) => !!val || 'Email is required!',
          (v) => validateEmail(v) || 'E-mail must be valid',
        ]"
      />
      <CRInput
        v-model="companyFormData.emailSenderName"
        :disabled="!hasPermissionToEdit"
        label="Email Sender Name"
        placeholder="Email Sender Name"
        :rules="[(val) => !!val || 'Email Sender Name is required!']"
      />
      <v-layout row>
        <v-flex style="padding-right: 5px">
          <CRInput
            v-model="companyFormData.phone"
            :disabled="!hasPermissionToEdit"
            label="Company Phone"
            placeholder="Company Phone"
            mask="(###) ###-####"
            :rules="[
              (val) => !!val || 'Company Phone is required!',
              (v) => validatePhone(v) || 'Company Phone must be valid',
            ]"
          />
        </v-flex>
        <v-flex style="padding-left: 5px">
          <CRInput
            v-model="companyFormData.partnerPhone"
            :disabled="!hasPermissionToEdit"
            label="Dispatch Phone"
            placeholder="Dispatch Phone"
            mask="(###) ###-####"
            :rules="[
              (v) =>
                validatePhone(v) | (v === '') || 'Dispatch Phone must be valid',
            ]"
          />
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex style="padding-right: 5px">
          <CRInput
            v-model="companyFormData.smsPhone"
            :disabled="!hasPermissionToEdit"
            label="Mobile Phone"
            placeholder="Mobile Phone"
            mask="(###) ###-####"
            :rules="[
              (v) =>
                validatePhone(v) | (v === '') || 'Mobile Phone must be valid',
            ]"
          />
        </v-flex>
        <v-flex style="padding-left: 5px">
          <CRInput
            v-model="companyFormData.fax"
            :disabled="!hasPermissionToEdit"
            label="Fax"
            placeholder="Fax"
            mask="(###) ###-####"
          />
        </v-flex>
      </v-layout>
      <CRInput
        v-model="companyFormData.websiteUrl"
        :disabled="!hasPermissionToEdit"
        label="Website URL"
        placeholder="Website URL"
      />
      <CRInput
        v-model="companyFormData.redirectPageUrl"
        :disabled="!hasPermissionToEdit"
        label="Redirect URL"
        placeholder="Redirect URL"
      />
      <hr class="section-divider" />
      <CRInput
        v-model="companyFormData.dotNumber"
        :disabled="!hasPermissionToEdit"
      >
        <template #label>
          <span style="display: flex; justify-content: space-between">
            <div>DOT Number</div>
            <div style="display: flex; align-items: end; padding-bottom: 2px" class="font-11 text-gray">{{ dotLastRetrieved }}</div>
          </span>
        </template>
      </CRInput>
      <div v-if="companyFormData.dotStatusType && companyFormData.dotStatusType.key === 'out_of_service'">
        <v-layout row>
          <CRInput
            :value="companyFormData.dotStatusType.label"
            label="DOT Status"
            readonly="true"
            class="padding-r-1 w-two-fourths red-text"
            placeholder="N/A"
          />
          <CRInput
            :value="dotOutOfServiceDate"
            label="Out of Service Date"
            readonly="true"
            class="padding-l-1 w-two-fourths red-text"
          />
        </v-layout>
      </div>
      <div v-else>
        <v-layout row>
          <CRInput
            :value="companyFormData.dotStatusType ? companyFormData.dotStatusType.label : ''"
            label="DOT Status"
            readonly="true"
            class="padding-r-1 w-two-fourths"
            placeholder="N/A"
            :class="{
              'red-text': companyFormData.dotStatusType && companyFormData.dotStatusType.label === 'Inactive'
            }"
          />
          <CRInput
            :value="dotAuthorityStatus"
            label="Operating Authority Status"
            readonly="true"
            class="padding-l-1 w-two-fourths"
            :class="{
              'red-text': dotAuthorityStatus === 'Not Authorized'
            }"
            placeholder="N/A"
          />
        </v-layout>
        <v-layout row>
          <CRInput
            :value="dotAuthorizedForPassenger"
            label="Authorized for Passenger"
            readonly="true"
            class="padding-r-1 w-two-fourths"
            :class="{
              'red-text': dotAuthorizedForPassenger === 'No'
            }"
            placeholder="N/A"
          />
          <CRInput
            :value="dotAuthorizedForHire"
            label="Authorized for Hire"
            readonly="true"
            class="padding-l-1 w-two-fourths"
            placeholder="N/A"
            :class="{
              'red-text': dotAuthorizedForHire === 'No'
            }"
          />
        </v-layout>
        <v-layout row>
          <CRInput
            :value="dotCarrierOperation"
            label="Carrier Operation"
            readonly="true"
            class="padding-r-1 w-two-fourths"
            placeholder="N/A"
          />
          <CRInput
            :value="companyFormData.dotCarrierState"
            label="Carrier State"
            readonly="true"
            class="padding-l-1 w-two-fourths"
            placeholder="N/A"
          />
        </v-layout>
      </div>
      <hr class="section-divider" />
      <template>
        <CRSelect
          v-model="companyFormData.partnerTypeId"
          :disabled="!hasPermissionToEdit"
          item-text="label"
          item-value="id"
          :items="partnerTypes"
          class="w-full partner-type-field"
          hide-details
          placeholder="Partner Type"
        >
          <template #label>
            <span class="padding-b-1" style="display: flex; justify-content: space-between">
              <div>Partner Type</div>
              <div style="display: flex; align-items: end; padding-bottom: 2px" class="font-11 text-gray">{{ partnerLabelsLastUpdated }}</div>
            </span>
          </template>
        </CRSelect>
        <v-layout>
          <v-checkbox
          v-model="companyFormData.isPreferred"
          hide-details
          :ripple="false"
          class="certified-partner-checkbox margin-t-0 padding-t-0"
          style="margin-bottom: 6px"
        >
          <template #label>
            <span style="font-size: 14px">
              Is Certified Partner
            </span>
          </template>
        </v-checkbox>
        </v-layout>
      </v-flex>
        <v-layout row>
          <CRSelect
            v-model="companyFormData.onboardingStatusTypeId"
            :disabled="!hasPermissionToEdit"
            item-text="label"
            item-value="id"
            label="Onboarding Status"
            :items="onboardingStatusTypes"
            class="padding-r-1 w-two-fourths"
          />
          <CRSelect
            v-model="companyFormData.charterUPOperatingStatusId"
            :disabled="!hasPermissionToEdit"
            item-text="label"
            item-value="id"
            label="CharterUP Operating Status"
            :items="charterUPOperatingStatusTypes"
            class="padding-l-1 w-two-fourths"
          />
        </v-layout>
        <hr class="section-divider" />
      </template>
      <v-layout row>
        <CRSelect
          v-model="companyFormData.insuranceOnFile"
          :disabled="!hasPermissionToEdit"
          label="Insurance On File"
          :items="[
            { name: 'Yes', id: true },
            { name: 'No', id: false },
          ]"
          item-text="name"
          item-value="id"
          placeholder="Select Insurance On File"
          class="padding-r-1 w-two-fourths"
        />
        <CRInput
          v-model="companyFormData.insuranceExpirationDate"
          :disabled="!hasPermissionToEdit"
          label="Insurance Expiration Date"
          placeholder="Insurance Expiration Date"
          type="date"
          class="padding-l-1 w-two-fourths"
        />
      </v-layout>
      <v-layout row>
        <CRSelect
          v-model="companyFormData.insuranceActiveTypeId"
          :disabled="!hasPermissionToEdit"
          label="Insurance Active"
          :items="insuranceActiveTypes"
          item-text="name"
          item-value="id"
          placeholder="Select Insurance Active"
          class="padding-r-1 w-two-fourths"
        />
        <CRSelect
          v-model="companyFormData.internationalOperation"
          :disabled="!hasPermissionToEdit"
          label="International Operation"
          :items="[
            { name: 'Yes', id: true },
            { name: 'No', id: false },
          ]"
          item-text="name"
          item-value="id"
          placeholder="Select International Operation"
          class="padding-l-1 w-two-fourths"
        />
      </v-layout>
      <v-layout row>
        <CRInput
          v-model="companyFormData.maxCharterPassenger"
          :disabled="!hasPermissionToEdit"
          type="text"
          label="Max Charter PAX"
          placeholder="e.g. 55"
          class="padding-r-1 w-two-fourths"
        />
        <CRInput
          v-model="companyFormData.maxMiniPassenger"
          :disabled="!hasPermissionToEdit"
          type="text"
          label="Max Mini PAX"
          placeholder="e.g. 20"
          class="padding-l-1 w-two-fourths"
        />
      </v-layout>
    </v-form>
    <div class="edit-company-sidebar--button-spacer"></div>
    <v-btn
      v-show="hasPermissionToEdit"
      id="edit-company-save-button"
      class="edit-company-save-button"
      color="primary"
      style="margin: 0; border-radius: 0"
      @click="editCompanyDetails"
    >
      Save
    </v-btn>
  </div>
</template>

<script>
import { EventBus } from '@/utils/event-bus'
import { deepClone } from '@/utils/deepClone'
import { validateEmail, validatePhone } from '@/utils/validators'
import { isoToString } from '@/utils/time'
import companies from '@/services/companies'
import { getOnboardingStatusTypes } from '@/services/types'
import { SplitFeatureFlag } from '@/utils/enum'
import { mapActions } from 'vuex'
import { DateTime } from 'luxon'

export default {
  props: {
    companyId: {
      type: Number,
      default: null,
    },
    company: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      validateEmail,
      validatePhone,
      partnerTypes: [],
      onboardingStatusTypes: [],
      charterUPOperatingStatusTypes: [],
      companyFormData: {
        address: {
          addressName: null,
          city: null,
          completeAddress: null,
          lat: null,
          lng: null,
          postalCode: null,
          state: null,
          country: null,
          street1: null,
          timeZone: null,
        },
        dotNumber: '',
        email: '',
        emailSenderName: '',
        phone: '',
        partnerPhone: '',
        smsPhone: '',
        fax: '',
        websiteUrl: '',
        redirectPageUrl: '',
        partnerTypeId: '',
        onboardingStatusTypeId: '',
      },
      insuranceActiveTypes: [
        {
          id: 1,
          name: 'Active',
        },
        {
          id: 2,
          name: 'Inactive',
        },
        {
          id: 3,
          name: 'N/A',
        },
      ],
    }
  },
  computed: {
    hasPermissionToEdit() {
      return this.$store.getters['auth/hasPermission'](
        'canEditAffiliatesDetails'
      )
    },
    dotLastRetrieved() {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        ? Intl.DateTimeFormat().resolvedOptions().timeZone
        : ''
      return this.companyFormData.dotUpdatedAt ? `Last Retrieved ${isoToString(this.companyFormData.dotUpdatedAt, userTimeZone)}` : ''
    },
    dotOutOfServiceDate() {
      if (this.companyFormData.dotOutOfServiceDate) {
        return DateTime.fromISO(this.companyFormData.dotOutOfServiceDate).toLocaleString()
      }
      return ''
    },
    dotAuthorityStatus() {
      if (this.companyFormData.dotAuthorized === null) {
        return ''
      }
      if (this.companyFormData.dotAuthorized) {
        return 'Authorized'
      }
      return 'Not Authorized'
    },
    dotAuthorizedForPassenger() {
      if (this.companyFormData.dotAuthorizedForPassenger === null) {
        return ''
      }
      if (this.companyFormData.dotAuthorizedForPassenger) {
        return 'Yes'
      }
      return 'No'
    },
    dotAuthorizedForHire() {
      if (this.companyFormData.dotAuthorizedForHire === null) {
        return ''
      }
      if (this.companyFormData.dotAuthorizedForHire) {
        return 'Yes'
      }
      return 'No'
    },
    dotCarrierOperation() {
      if (this.companyFormData.dotInterstateAllowed === null) {
        return ''
      }
      if (this.companyFormData.dotInterstateAlowed) {
        return 'Interstate'
      }
      return 'Intrastate Only'
    },
    partnerLabelsLastUpdated() {
      if (this.companyFormData.partnerLabelsUpdatedAt) {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
          ? Intl.DateTimeFormat().resolvedOptions().timeZone
          : ''
        const datetime = DateTime.fromISO(this.companyFormData.partnerLabelsUpdatedAt, { zone: userTimeZone })
        let result = `${datetime.toFormat('M/dd/yyyy t ZZZZ')}`
        if (this.companyFormData.partnerLabelsUpdatedBy) {
          result += ` • ${this.companyFormData.partnerLabelsUpdatedBy.firstName} ${this.companyFormData.partnerLabelsUpdatedBy.lastName}`
        }
        return result
      }
    },
  },
  async mounted() {
    await Promise.all([this.getPartnerTypes(), this.getOnboardingTypes(), this.getCharterUPOperatingStatusTypes()])
    if (!this.company) {
      await this.loadCompany(this.companyId)
    } else {
      this.companyFormData = deepClone(this.company)
    }
  },
  methods: {
    ...isoToString,
    ...mapActions({
      isFeatureEnabled: 'split/isFeatureEnabled',
    }),
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    async loadCompany(companyId) {
      const companyResponse = await companies.getCompany(companyId)
      this.companyFormData = companyResponse.data.company
    },
    handleAddressClear() {
      this.companyFormData.address = {
        addressName: null,
        city: null,
        completeAddress: null,
        lat: null,
        lng: null,
        postalCode: null,
        state: null,
        country: null,
        street1: null,
        timeZone: null,
      }
    },
    handleAddressSelect(event) {
      this.companyFormData.address = {
        addressName: event.place.addressName || null,
        city: event.place.city || null,
        completeAddress: event.place.completeAddress || null,
        lat: event.place.lat || null,
        lng: event.place.lng || null,
        postalCode: event.place.postalCode || null,
        state: event.place.state || null,
        country: event.place.country || null,
        street1: event.place.street1 || null,
        timeZone: event.place.timeZone || null,
      }
    },
    async editCompanyDetails() {
      if (this.hasPermissionToEdit) {
        const form = this.$refs['form']
        if (!form.validate()) {
          return
        }
        const params = {
          id: this.company?.companyId || this.companyId,
          payload: this.companyFormData,
        }
        await companies.modifyCompany(params)
        EventBus.$emit('affiliates-details-refresh')
        this.close()
      }
    },
    async getOnboardingTypes() {
      const params = { pageSize: -1 }
      const onboardingStatusTypeResults = await getOnboardingStatusTypes(params)
      const onboardingStatusTypesAll = onboardingStatusTypeResults.data?.resultList
      this.onboardingStatusTypes = onboardingStatusTypesAll
    },
    async getCharterUPOperatingStatusTypes() {
      const result = await companies.getCharterUPOperatingStatuses()
      this.charterUPOperatingStatusTypes = result?.data?.charterUpOperatingStatuses
    },
    async getPartnerTypes() {
      const partnerTypeResults = await this.$store
        .dispatch('types/getPartnerTypes')
        .catch((e) => {
          console.error(e)
        })
      this.partnerTypes = partnerTypeResults.data.resultList
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-company-sidebar {
  height: 100%;
  width: 500px;

  &--button-spacer {
    min-height: 71px;
    width: 100%;
  }
}

.partner-type-field ::v-deep .v-input__control {
  min-height: auto;
}

.certified-partner-checkbox ::v-deep .v-input--selection-controls__input {
  margin-right: 6px;
}

.red-text::v-deep input {
  color: $error-new !important;
}

.section-divider {
  border: 0;
  border-top: 1px solid $gray-light;
  margin-bottom: 20px;
}

.edit-company-save-button {
  display: flex;
  position: fixed;
  flex-direction: column;
  font-size: 18px;
  color: white;
  bottom: 0;
  width: inherit;
  height: 71px;
  padding: 24px 0;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  width: 500px;
}
</style>
